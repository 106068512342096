/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  CardContent,
  Container, Typography, Grid, Grow,
} from '@mui/material';
import ReactGA from 'react-ga';
import { useWilocate } from '../../context';
import { SvgButton, CreateEstimateDialog } from '../../components';

export function InternationalMoves() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const { setCreateEstimateDialogOpen } = useWilocate();
  return (
    <Grow in timeout={300}>
      <div>
        <div style={{
          width: '100vw',
          minHeight: '95vh',
          backgroundColor: '#155EBA',
        }}
        >
          <div style={{
            minHeight: '95vh',
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
          }}
          >
            <Container>
              <Grid container>
                <CardContent>
                  <Typography sx={{ color: '#ffffff', typography: { sm: 'h1', xs: 'h2' }, fontWeight: 'bold' }} style={{ fontWeight: 'bold' }}>
                    International Moves
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    color="#ffffff"
                  >
                    You are excited about getting a job outside Zambia and cannot look forward to embracing new
                    cultures, learning a new language, or you are simply moving back to your home country – Whatever
                    the case is, we will miss you!
                    <br />
                    Moving to another country is very exciting but what will you do with your beloved furniture?
                    Contact Wilocate of course! We will pack, transport and help with getting all your paperwork ready
                    for border authorities!
                    <br />
                    We facilitate moves from Zambia to South Africa and vice versa, get in touch with us for more
                    information and help us help you plan your relocation affordably and efficiently.
                  </Typography>
                </CardContent>
                <CardContent>
                  <div>
                    <SvgButton
                      onClick={() => setCreateEstimateDialogOpen(true)}
                    >
                      <Typography variant="subtitle2" align="center">
                        Get Quote
                      </Typography>
                    </SvgButton>
                  </div>
                </CardContent>
                <CreateEstimateDialog />
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    </Grow>
  );
}
