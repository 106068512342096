import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { ReviewCard, Section } from '../../components';
import { useWilocate } from '../../context';

export function Testimonials() {
  const { place } = useWilocate();
  return (
    <Section id="Testimonials">
      <Container maxWidth="xl">
        <Grid item sm={6} />
        <Grid
          item
          sm={6}
          style={{
            paddingBottom: '4em',
            paddingTop: '4em',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h2"
            color="secondary"
            sx={{ typography: { fontWeight: 'bold' } }}
            textAlign="right"
          >
            Our customers love us!
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          {place?.result?.reviews?.filter((review: any) => review.rating > 3).map((review: any) => (
            <Grid item sm={4} xs={12} key={review}>
              <ReviewCard
                avatar={review.profile_photo_url}
                name={review.author_name}
                rating={review.rating}
                text={review.text}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}
