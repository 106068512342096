import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Medium',
      'Roboto',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#2373cc',
      dark: '#2373cc',
      light: '#2373cc',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2373cc',
      dark: '#2373cc',
      light: '#2373cc',
      contrastText: '#fff',
    },
    info: {
      main: '#2373cc',
    },
    text: {
      secondary: '#00363a',
    },
  },
});

export default theme;
