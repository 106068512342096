/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  CardContent,
  Container, Grow, Typography,
} from '@mui/material';
import ReactGA from 'react-ga';
import { useWilocate } from '../../context';
import { SvgButton, CreateEstimateDialog } from '../../components';

export function LocalMoves() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const { setCreateEstimateDialogOpen } = useWilocate();
  return (
    <Grow in timeout={300}>
      <div>
        <div style={{
          width: '100vw',
          minHeight: '95vh',
          backgroundColor: '#155EBA',
        }}
        >
          <div style={{
            minHeight: '95vh',
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
          }}
          >
            <Container>
              <CardContent>
                <Typography
                  variant="h1"
                  style={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                  }}
                >
                  Local Moves
                </Typography>
              </CardContent>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  color="#ffffff"
                >
                  Are you moving within Lusaka or to and from Lusaka and require a moving partner? Look no further
                  than Wilocate! Moving within Zambia can be a difficult decision due to how expensive out of town
                  moving is, the number of movers available and the uncertainty of their professionalism, but
                  Wilocate is the exception. We are a young company with the right amount of energy, personnel and
                  work ethic to satisfy some of the most demanding individuals and companies in Zambia.
                  <br />
                  <br />
                  Whether you’re moving to the next street, across the neighbourhood, to a new suburb, Copperbelt,
                  North-Western, Eastern, Northern or Southern Province, Wilocate will provide you with a world-class
                  moving service that you can brag about!
                  <br />
                  <br />
                  Wilocate believes in keeping every customer informed and engaged and we therefore maintain
                  constant communication throughout the phases of the move, ensuring our clients are kept up to
                  date and informed. We maintain as much involvement in your move as you require, priding
                  ourselves in our proactive attitude, honesty and customer support.
                  <br />
                  <br />
                  We vet and provide a diverse fleet of vehicles from small to large companies operating in the
                  transportation industry therefore allowing us to adapt to each and every move whether you need a
                  small 3.5ton canter or a 30ton containerised truck.
                  <br />
                  <br />
                  Wilocate has been successful for over the last few years in our industry because we provide cost-
                  effective moving solutions that our clients can trust in. We have grown through client referrals and
                  recommendations which is evident that we should be your mover of choice no matter where you are
                  moving to within Zambia.
                </Typography>
              </CardContent>
              <CardContent>
                <div>
                  <SvgButton
                    onClick={() => setCreateEstimateDialogOpen(true)}
                  >
                    <Typography variant="subtitle2" align="center">
                      Get Quote
                    </Typography>
                  </SvgButton>
                </div>
              </CardContent>
              <CreateEstimateDialog />
            </Container>
          </div>
        </div>
      </div>
    </Grow>
  );
}
