/* eslint-disable max-len */
import React from 'react';
import { ServicesMenuItemContent } from '../ServicesMenuItemContent';

export function InsuranceMenuItemContent() {
  return (
    <div>
      <ServicesMenuItemContent
        title="Insurance"
        subTitle="How can I benefit from having an insurance cover?"
        text="At Wilocate, we pride ourselves on our low rate of damages and insurance claims. However, accidents happen, and we strongly believe in the importance of insuring your household effects as comprehensively as possible."
        linkTo="/insurance"
        taglines={[
          'Peace of mind.',
          'Theft by forced entry.',
          'Handling damage.',
          'Storm damage, including flooding.',
        ]}
      />
    </div>
  );
}
