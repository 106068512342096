/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  CardContent,
  Container, Grow, Typography,
} from '@mui/material';
import ReactGA from 'react-ga';
import { useWilocate } from '../../context';
import { SvgButton } from '../../components/SvgButton/SvgButton';
import { CreateEstimateDialog } from '../../components';

export function Insurance() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const { setCreateEstimateDialogOpen } = useWilocate();
  return (
    <Grow in timeout={300}>
      <div>
        <div style={{
          width: '100vw',
          minHeight: '95vh',
          backgroundColor: '#155EBA',
        }}
        >
          <div style={{
            minHeight: '95vh',
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
          }}
          >
            <Container>
              <CardContent>
                <Typography sx={{ color: '#ffffff', typography: { sm: 'h1', xs: 'h2', fontWeight: 'bold' } }} style={{ fontWeight: 'bold' }}>
                  Insurance
                </Typography>
              </CardContent>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  color="#ffffff"
                >
                  The insurance cover is underwritten by Hollard Zambia and our brokers are African Insurance
                  Brokers Limited.
                  <br />
                  <br />
                  Why should I insure my goods for a Local, International or Office Move?
                  At Wilocate, we pride ourselves on our incredibly low rate of damages and insurance claims.
                  However, accidents do happen, and we strongly believe in the importance of you insuring your
                  household effects as comprehensively as possible, for the following reasons:
                  <br />
                  <br />
                  <ul>
                    <li> Peace of mind – Your financial and domestic security is at risk if you have no insurance</li>
                    <li> Handling damage – a risk every time furniture is moved, irrespective of the moving company used</li>
                    <li> Bad terrain</li>
                    <li> Storm damage, including flooding</li>
                    <li> Riot and strike action</li>
                    <li> Theft by forced entry</li>
                    <li> Warehouse theft</li>
                    <li> Fire in vehicle</li>
                    <li> To avoid loss of general household insurance cover while your goods are in transit</li>
                    <li> Hijacking</li>
                    <li> Vandalism</li>
                    <li> Terrorism</li>
                  </ul>
                  <br />
                  <br />
                  How can I benefit from having an insurance cover?
                  <br />
                  <br />
                  We are able to offer you genuine ALL risk insurance cover for your move at competitive rates. For
                  your peace of mind, all you need to do is complete the Insurance Application Form, valuing all your
                  effects at ‘replacement values’, and submit it to us before your moving day. Complete the insurance
                  form and protect your furniture from as low as ZMW 300 (T’s&amp;C’s apply).
                </Typography>
              </CardContent>
              <CardContent>
                <div>
                  <SvgButton
                    onClick={() => setCreateEstimateDialogOpen(true)}
                  >
                    <Typography variant="subtitle2" align="center">
                      Get Quote
                    </Typography>
                  </SvgButton>
                </div>
              </CardContent>
              <CreateEstimateDialog />
            </Container>
          </div>
        </div>
      </div>
    </Grow>
  );
}
