/* eslint-disable react/require-default-props */
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

interface EditImageDataProps {
  src?: (val: any) => string
  alt?: (val: any) => string
}

export interface ImagesProps {
  editImageData?: EditImageDataProps
  imageData: any
  cols: number
}

export function Images({ imageData, editImageData, cols }: ImagesProps) {
  const getSrcValue = (item: any) => {
    if (editImageData?.src) {
      return editImageData.src(item);
    }
    return item.src;
  };

  const getAltValue = (item: any) => {
    if (editImageData?.alt) {
      return editImageData.alt(item);
    }
    return item.alt;
  };
  return (
    <ImageList cols={cols}>
      {imageData?.slice(0, 8).map((item: any) => (
        <ImageListItem>
          <img
            src={getSrcValue(item)}
            srcSet={getSrcValue(item)}
            alt={getAltValue(item)}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
