/* eslint-disable max-len */
import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Container, Grid, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Section } from '../../components';
import WilocateStepAssets1 from './Wilocate StepsAsset 1-1.png';
import WilocateStepAssets2 from './Wilocate StepsAsset 1-2.png';
import WilocateStepAssets3 from './Wilocate StepsAsset 1-3.png';

export function AboutUs() {
  return (
    <div
      style={{
        background: '#2373cc',
      }}
    >
      <Section id="About">
        <Container>
          <Grid
            container
            direction="row"
            spacing={4}
          >
            <Grid item xs={12}>
              <Typography color="white" variant="h2" gutterBottom style={{ fontWeight: 'bold' }}>
                Full range moving services & storage company
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="white" variant="subtitle1" gutterBottom>
                Wilocate has been offering house removals since 2019 and are committed to delivering the highest quality service at all times. Our experienced team will offer a fully personalized service whether you are moving locally, nationally or internationally. Wilocate is a removal company with a varied menu of services including a choice of packing services, home set up service and secure storage facilities.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ height: '1.7em' }} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Card
                elevation={0}
                style={{
                  background: 'transparent',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <CardMedia
                  component="img"
                  image={WilocateStepAssets1}
                  alt="Fill out the Form"
                />
                <CardContent>
                  <Typography
                    variant="h5"
                    textAlign="center"
                    style={{
                      fontWeight: 'bold',
                      color: 'whitesmoke',
                      fontSize: '1.7em',
                    }}
                  >
                    Fill out the Form
                  </Typography>
                  <Typography
                    variant="body2"
                    textAlign="center"
                    gutterBottom
                    style={{
                      color: 'whitesmoke',
                    }}
                  >
                    Start here by filling out the simple, secure form.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Card
                elevation={0}
                style={{
                  background: 'transparent',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <CardMedia
                  component="img"
                  image={WilocateStepAssets2}
                  alt="Approve the Quotation"
                />
                <CardContent>
                  <Typography
                    variant="h5"
                    textAlign="center"
                    gutterBottom
                    style={{
                      fontWeight: 'bold',
                      color: 'whitesmoke',
                      fontSize: '1.7em',
                    }}
                  >
                    Approve the Quotation
                  </Typography>
                  <Typography
                    variant="body2"
                    textAlign="center"
                    style={{
                      color: 'whitesmoke',
                    }}
                  >
                    You will receive a quotation based on your information
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Card
                elevation={0}
                style={{
                  background: 'transparent',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <CardMedia
                  component="img"
                  image={WilocateStepAssets3}
                  alt="Get Moving!"
                />
                <CardContent>
                  <Typography
                    variant="h5"
                    textAlign="center"
                    gutterBottom
                    style={{
                      fontWeight: 'bold',
                      color: 'whitesmoke',
                      fontSize: '1.7em',
                    }}
                  >
                    Get Moving!
                  </Typography>
                  <Typography
                    variant="body2"
                    textAlign="center"
                    style={{
                      color: 'whitesmoke',
                    }}
                  >
                    Our team will organize and arrange everything and have you moved in no time.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </div>
  );
}
