/* eslint-disable max-len */
import React from 'react';
import { ServicesMenuItemContent } from '../ServicesMenuItemContent';

export function InternationalMovesMenuItemContent() {
  return (
    <div>
      <ServicesMenuItemContent
        title="International Moves"
        subTitle="The best move you&apos;ll ever make"
        text="You are excited about getting a job outside Zambia and look forward to embracing new cultures, learning a new language, or simply moving back to your home country – Whatever the case, we will miss you! Moving to another country is exciting, but what will you do with your beloved furniture? Contact Wilocate, of course!"
        linkTo="/international-moves"
        taglines={[
          'Full service packing',
          'Professional transportation',
          'Stress free customs brokerage',
          'Affordable rates',
        ]}
      />
    </div>
  );
}
