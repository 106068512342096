/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { CardHeader } from '@mui/material';

export interface CardProps {
  title: string
  picture?: any
  text: string
  link?: string
  buttonLink?: string
  name?: string
  rating?: number
  reviewDate?: string
}

export function UpdateCard({
  title,
  picture,
  text,
  name,
  rating,
}: CardProps) {
  return (
    <Card
      elevation={0}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      {title && (
        <CardHeader
          title={title}
        />
      )}
      {picture && (
      <CardMedia
        component="img"
        image={picture}
        alt="post picture"
      />
      )}
      <CardContent>
        {rating && (
        <Rating name="read-only" value={rating} readOnly />
        )}
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </CardContent>
      {name && (
      <Typography variant="h5" color="text.secondary">
        |
        {' '}
        {name}
      </Typography>
      )}
    </Card>
  );
}
