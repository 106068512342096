/* eslint-disable max-len */
import React from 'react';
import { Footer as TZFooter } from '@think-zambia-foundation/components';
import {
  useNavigate,
} from 'react-router-dom';
import { Toolbar } from '@mui/material';
import { useWilocate } from '../../context';

export function Footer() {
  const { place, setCreateEstimateDialogOpen } = useWilocate();
  const navigate = useNavigate();

  return (
    <>
      <Toolbar />
      <TZFooter
        aboutText="Moving company for both residential and commercial clients."
        companyName={place?.result?.name}
        addressLine1={place?.result?.vicinity}
        addressLine2={place?.result?.address_components[4]?.long_name}
        addressLine3={place?.result?.address_components[5]?.long_name}
        copyright={place?.result?.name}
        facebook="wilocate"
        linkedIn="wilocate-logistics"
        twitter="wilocatezm"
        phone={place?.result?.international_phone_number || place?.result?.formatted_phone_number}
        columns={[
          {
            columnTitle: 'Business Hours',
            columnMenuList: [{
              text: `${place?.result?.opening_hours?.open_now ? 'Open Now' : 'Closed Now'}`,
              href: '',
              onClick: () => null,
            }, {
              text: 'Monday: 8:00 am - 5:00 pm',
              href: '',
              onClick: () => null,
            }, {
              text: 'Tuesday: 8:00 am - 5:00 pm',
              href: '',
              onClick: () => null,
            }, {
              text: 'Wednesday: 8:00 am - 5:00 pm',
              href: '',
              onClick: () => null,
            }, {
              text: 'Thursday: 8:00 am - 5:00 pm',
              href: '',
              onClick: () => null,
            }, {
              text: 'Friday: 8:00 am - 5:00 pm',
              href: '',
              onClick: () => null,
            }, {
              text: 'Saturday: 8:00 am - 5:00 pm',
              href: '',
              onClick: () => null,
            }, {
              text: 'Sunday: 8:00 am - 5:00 pm',
              href: '',
              onClick: () => null,
            }],
          },
          {
            columnTitle: 'Moves',
            columnMenuList: [{
              text: 'Office Moves',
              href: '/office-moves',
              onClick: () => {
                window.scrollTo({ top: 0 });
                navigate('/office-moves');
              },
            }, {
              text: 'International Moves',
              href: '/international-moves',
              onClick: () => {
                window.scrollTo({ top: 0 });
                navigate('/international-moves');
              },
            }, {
              text: 'Local Moves',
              href: '/local-moves',
              onClick: () => {
                window.scrollTo({ top: 0 });
                navigate('/local-moves');
              },
            }, {
              text: 'Staff Relocations',
              href: '/staff-relocation',
              onClick: () => {
                window.scrollTo({ top: 0 });
                navigate('/staff-relocation');
              },
            }],
          },
          {
            columnTitle: 'Useful Information',
            columnMenuList: [{
              text: 'Get Directions',
              href: '',
              onClick: () => window.open('https://www.google.com/maps/dir/-15.3875259,28.3228165/wilocate+logistics/@-15.3938877,28.2982992,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x19408fb086da1f93:0xe94dd63e864d111!2m2!1d28.4123029!2d-15.4071408', '_blank'),
            }, {
              text: 'Get a Quote',
              href: '',
              onClick: () => setCreateEstimateDialogOpen(true),
            }],
          },
        ]}
      />
      <Toolbar />
    </>
  );
}
