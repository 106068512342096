import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
// import CardMedia from '@mui/material/CardMedia';
import { useWilocate } from '../../context';
import { Images } from '../../components';

export function Gallery() {
  const { place } = useWilocate();
  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              paddingBottom: '4em',
              paddingTop: '4em',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h2"
              color="secondary"
              sx={{ typography: { fontWeight: 'bold' } }}
              textAlign="left"
            >
              Let&apos;s get packing!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Images
              imageData={place?.result?.photos}
              editImageData={{
                src: (val: any) => `https://maps.googleapis.com/maps/api/place/photo?photo_reference=${val.photo_reference}&key=${process.env.REACT_APP_PLACES_API_KEY}&maxwidth=700&maxheight=700`,
                alt: () => 'Wilocate Gallery images',
              }}
              cols={4}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
