/* eslint-disable max-len */
import React from 'react';
import { ServicesMenuItemContent } from '../ServicesMenuItemContent';

export function StaffRelocationMenuItemContent() {
  return (
    <div>
      <ServicesMenuItemContent
        title="Staff Relocation"
        subTitle="Your employees are in safe hands"
        text="Corporate move? Moving employees’ entire families? Our trained and professional staff are there to make this transition as smooth as possible and offer unrivaled quality service."
        linkTo="/staff-relocation"
        taglines={[
          'Corporate staff relocations.',
          'Trained and professional staff.',
          'Unrivaled quality service.',
          'Packing, loading, and unloading.',
        ]}
      />
    </div>
  );
}
