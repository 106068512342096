/* eslint-disable max-len */
import React from 'react';
import { ServicesMenuItemContent } from '../ServicesMenuItemContent';

export function OfficeMovesMenuItemContent() {
  return (
    <div>
      <ServicesMenuItemContent
        title="Office Moves"
        subTitle="The best move you&apos;ll ever make"
        text="Moving office and need a removals partner? Look no further than Wilocate for all your office relocation requirements. Our extensive service offering will take you around Zambia and the region."
        linkTo="/office-moves"
        taglines={[
          'Your trusted office movers.',
          'Organized, efficient, and hustle free.',
          'Trained and experienced staff.',
          'Disassemble and reassemble services.',
        ]}
      />
    </div>
  );
}
