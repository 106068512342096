import React from 'react';
import './App.css';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { Footer, ServicesMenu, WilocateNavBar } from './components';
import {
  InternationalMoves,
  StaffRelocation,
  OfficeMoves,
  LocalMoves,
  Home,
  Insurance,
} from './pages';
import { services } from './services';

const StreamKey = process.env.REACT_APP_WILOCATE_ANALYTICS_STREAM_KEY;

ReactGA.initialize(`${StreamKey}`);

function WilocateApp() {
  return (
    <div>
      <WilocateNavBar services={services} />
      <ServicesMenu services={services} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/local-moves" element={<LocalMoves />} />
        <Route path="/international-moves" element={<InternationalMoves />} />
        <Route path="/staff-relocation" element={<StaffRelocation />} />
        <Route path="/office-moves" element={<OfficeMoves />} />
        <Route path="/insurance" element={<Insurance />} />
      </Routes>
      <Footer />
    </div>
  );
}

export function App() {
  return (
    <BrowserRouter>
      <WilocateApp />
    </BrowserRouter>
  );
}
