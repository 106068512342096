/* eslint-disable max-len */
import React from 'react';
import {
  Button, Typography, Container, Grid, Hidden,
} from '@mui/material';
import { useWilocate } from '../../context';
import { CreateEstimateDialog, Section } from '../../components';
import WilocateLogo from '../../assets/WilocateLogo.png';

export function Title() {
  const { setCreateEstimateDialogOpen } = useWilocate();

  return (
    <Section id="Title">
      <Container>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Hidden smDown>
            <Grid item xs={12}>
              <Typography variant="h1" align="center" color="primary" sx={{ typography: { fontWeight: 'bold' } }}>
                Wilocate Logistics
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" align="center">
                Moving company for both residential and commercial clients
              </Typography>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              <img alt="Wilocate" src={WilocateLogo} loading="lazy" width={200} />
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              The best move you&apos;ll ever make
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              onClick={() => setCreateEstimateDialogOpen(true)}
              size="large"
              style={{
                padding: '1em 2.5em',
                borderRadius: 0,
              }}
            >
              GET QUOTE
            </Button>
          </Grid>
        </Grid>
      </Container>
      <CreateEstimateDialog />
    </Section>
  );
}
