import { Toolbar } from '@mui/material';
import React from 'react';

export function Section(props: { children: any, id: string, disableGutter?: true | undefined }){
  const {
    children,
    id,
    disableGutter,
  } = props;

  return (
    <div
      id={id}
      style={{
        width: '100vw',
      }}
    >
      {!disableGutter && (
        <>
          <Toolbar />
          <Toolbar />
        </>
      )}
      {children}
      {!disableGutter && (
        <>
          <Toolbar />
          <Toolbar />
        </>
      )}
    </div>
  );
}

Section.defaultProps = {
  disableGutter: undefined,
};
