/* eslint-disable max-len */
import * as React from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import {
  Paper,
  CardContent,
  Popper,
  Tabs,
  Tab,
  Fade,
  Grid,
  CardMedia,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SvgButton } from '../SvgButton';
import { useWilocate } from '../../context';
import WilocateLogo from '../../assets/WilocateLogo.png';

export interface ServicesMenuItemProps {
  key: any
  id: string
  text: string
  href: string
  content: React.FunctionComponent
}

export interface ServicesMenuProps {
  services: ServicesMenuItemProps[]
}

export function ServicesMenu({ services }: ServicesMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [value, setValue] = React.useState<any>();
  const [content, setContent] = React.useState<any>();
  const [serviceName, setServiceName] = React.useState<any>();
  const navigate = useNavigate();
  const location = useLocation();

  const { setCreateEstimateDialogOpen, setRelocationType } = useWilocate();

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setValue(index);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (location) {
      handlePopperClose();
      setRelocationType(location.pathname);
    }
  }, [location]);

  const open = Boolean(anchorEl);

  return (
    <div>
      <Container
        onMouseLeave={handlePopperClose}
        sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        <Popper
          open={open}
          anchorEl={anchorEl}
          id="menu-list-grow"
        >
          <div style={{ width: '100vw' }}>
            <Container>
              <Paper style={{
                borderRadius: 0,
              }}
              >
                <CardContent>
                  <CardContent>
                    <Container>
                      <Fade in timeout={1000}>
                        {content}
                      </Fade>
                    </Container>
                  </CardContent>
                  <CardContent>
                    <Container>
                      <SvgButton
                        onClick={() => setCreateEstimateDialogOpen(true)}
                        onMouseEnter={() => setRelocationType(serviceName)}
                      >
                        <Typography variant="subtitle2" align="center">
                          Get Quote
                        </Typography>
                      </SvgButton>
                    </Container>
                  </CardContent>
                </CardContent>
              </Paper>
            </Container>
          </div>
        </Popper>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Link
              to="/"
              style={{
                textDecoration: 'none',
              }}
              onMouseEnter={() => {
                handlePopperClose();
              }}
            >
              <CardContent>
                <CardMedia
                  component="img"
                  height="50"
                  image={WilocateLogo}
                  alt="Wilocate logo"
                />
              </CardContent>
            </Link>
          </Grid>
          <Grid item>
            <Tabs
              value={value}
              centered
              TabIndicatorProps={{
                sx: {
                  display: 'none',
                },
              }}
            >
              {services.map((item, index) => (
                <Tab
                  style={index === 0 ? {
                    zIndex: 100,
                    borderRight: 'solid thin whitesmoke',
                    borderLeft: 'solid thin whitesmoke',
                  } : {
                    zIndex: 100,
                    borderRight: 'solid thin whitesmoke',
                  }}
                  onMouseEnter={(event) => {
                    handlePopperOpen(event, index);
                    setContent(item.content);
                    setServiceName(item.text);
                  }}
                  data-key={index}
                  label={(
                    <CardContent>
                      <Typography style={
                        location.pathname.includes(item.href) ? {
                          fontWeight: '500',
                          color: '#2373cc',
                        } : {}
                      }
                      >
                        {item.text}
                      </Typography>
                    </CardContent>
                  )}
                  aria-owns={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(item.href);
                    handlePopperClose();
                  }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
