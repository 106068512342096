import { Grow } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { GetQuote } from '../../components';
import {
  AboutUs,
  Gallery,
  Testimonials,
  Title,
  Values,
} from '../../sections';

export function Home() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Grow in timeout={300}>
      <div style={{ paddingTop: '2em', alignContent: 'center' }}>
        <Title />
        <AboutUs />
        <Values />
        <Testimonials />
        <Gallery />
        <GetQuote />
      </div>
    </Grow>
  );
}
