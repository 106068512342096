import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { Section, UpdateCard } from '../../components';

const valuesList = [
  {
    picture: '',
    title: 'Relationship Building',
    text: 'We understand how important it is to develop strong relationships across all areas of our business, from our employees to our customers, our partners, and our suppliers.',
  },
  {
    picture: '',
    title: 'Agility and Responsiveness',
    text: 'Our company is young, making mistakes, learning and unlearning, and we are proud of it. We are agile, open-minded, and enthusiastic about innovation, and we count those qualities among the key strengths that make us so effective at providing our clients with the logistical solutions they need. We promise effective communication, transparency and accountability.',
  },
  {
    picture: '',
    title: 'Customer Success',
    text: 'We understand how impactful our business is on our customers’ lives, and we take pride in knowing that we are a positive driver of their success and attainment of a peace of mind.',
  },
  {
    picture: '',
    title: 'Trust',
    text: 'We know that Trust is everything, and understand how precious and how fragile it is. It is for this reason that every action we take is geared towards building and strengthening the trust our clients put in us. That means offering client-centric service, and never failing to deliver on our promises.',
  },
];

export function Values() {
  return (
    <Section id="Values">
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h1" color="secondary" sx={{ typography: { fontWeight: 'bold' } }}>Our values</Typography>
          </Grid>
          {valuesList?.map((value) => (
            <Grid item sm={3} xs={12}>
              <UpdateCard
                picture={value.picture}
                title={value.title}
                text={value.text}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}
