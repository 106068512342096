/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  CardContent,
  Container, Grow, Typography,
} from '@mui/material';
import ReactGA from 'react-ga';
import { useWilocate } from '../../context';
import { SvgButton } from '../../components/SvgButton/SvgButton';
import { CreateEstimateDialog } from '../../components';

export function OfficeMoves() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const { setCreateEstimateDialogOpen } = useWilocate();
  return (
    <Grow in timeout={300}>
      <div>
        <div style={{
          width: '100vw',
          minHeight: '95vh',
          backgroundColor: '#155EBA',
        }}
        >
          <div style={{
            minHeight: '95vh',
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
          }}
          >
            <Container>
              <CardContent>
                <Typography
                  variant="h1"
                  style={{
                    color: '#ffffff',
                    fontWeight: 'bold',
                  }}
                >
                  Office Moves
                </Typography>
              </CardContent>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  color="#ffffff"
                >
                  Moving office and need a removals partner? Look no further than Wilocate for all your office relocation requirements.
                  <br />
                  <br />
                  Wilocate has, over the years, leveraged client feedback to continuously improve its service delivery and knows how to move your office efficiently, and our support services will reduce any complications. Our extensive service offering will take you around Zambia and the region or simply right around the corner and get your new office operating as fast as possible. We colour code our labels and will survey both the old and new premises to familiarize ourselves with the floor plan, which will ensure the unpacking and delivery of furniture to the designated areas.
                  <br />
                  <br />
                  Trained, experienced, and efficient Wilocate personnel will pack any documentation and/ or office equipment according to your requirements. We engage vetted and qualified service providers to dismantle, disconnect, and unmount all your office furniture, electrical, and notice boards. They will set them up at the new office in readiness for you to occupy and use your equipment.
                  <br />
                  <br />
                  Our staff will ensure that the process of dismantling and reassembling office furniture runs smoothly. The cleaning-up of packing material immediately after unpacking, or at a pre-arranged date, leaves your new offices neat and ready for business.
                </Typography>
              </CardContent>
              <CardContent>
                <div>
                  <SvgButton
                    onClick={() => setCreateEstimateDialogOpen(true)}
                  >
                    <Typography variant="subtitle2" align="center">
                      Get Quote
                    </Typography>
                  </SvgButton>
                </div>
              </CardContent>
              <CreateEstimateDialog />
            </Container>
          </div>
        </div>
      </div>
    </Grow>
  );
}
