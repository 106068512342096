/* eslint-disable max-len */
import React from 'react';
import { ServicesMenuItemContent } from '../ServicesMenuItemContent';

export function LocalMovesMenuItemContent() {
  return (
    <div>
      <ServicesMenuItemContent
        title="Local Moves"
        subTitle="The best move you&apos;ll ever make"
        text="Whether you’re moving to the next street, across the neighbourhood, to a new suburb, Copperbelt, North-Western, Eastern, Northern or Southern Province, Wilocate will provide you with a world-class moving service that you can brag about!"
        linkTo="/local-moves"
        taglines={[
          'Move anywhere across the country.',
          'Stay informed during your move.',
          'Choose from a diverse fleet of vehicles.',
          "Zambia's trusted movers.",
        ]}
      />
    </div>
  );
}
