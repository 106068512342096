/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import {
  ServicesMenuItemProps,
  LocalMovesMenuItemContent,
  InternationalMovesMenuItemContent,
  OfficeMovesMenuItemContent,
  StaffRelocationMenuItemContent,
  InsuranceMenuItemContent,
} from './components';

export const services: ServicesMenuItemProps[] = [
  {
    id: '1',
    key: '1',
    text: 'Local Moves',
    href: '/local-moves',
    content: LocalMovesMenuItemContent,
  }, {
    id: '2',
    key: '2',
    text: 'International Moves',
    href: '/international-moves',
    content: InternationalMovesMenuItemContent,
  }, {
    id: '3',
    key: '3',
    text: 'Office Moves',
    href: '/office-moves',
    content: OfficeMovesMenuItemContent,
  }, {
    id: '4',
    key: '4',
    text: 'Staff Relocation',
    href: '/staff-relocation',
    content: StaffRelocationMenuItemContent,
  },
  {
    id: '5',
    key: '5',
    text: 'Insurance',
    href: '/insurance',
    content: InsuranceMenuItemContent,
  },
];
