/* eslint-disable max-len */
import {
  CardContent,
  Container, Grow, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import { useWilocate } from '../../context';
import { SvgButton } from '../../components/SvgButton/SvgButton';
import { CreateEstimateDialog } from '../../components';

export function StaffRelocation() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const {
    setCreateEstimateDialogOpen,
  } = useWilocate();

  return (
    <Grow in timeout={300}>
      <div style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: '#155EBA',
      }}
      >
        <div style={{
          minHeight: '95vh',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        >
          <Container>
            <CardContent>
              <Typography sx={{ color: '#ffffff', typography: { sm: 'h1', xs: 'h2' } }} style={{ fontWeight: 'bold' }}>
                Staff Relocations
              </Typography>
              <Typography
                variant="subtitle1"
                color="#ffffff"
              >
                With Wilocate, your employees are in safe hands! We understand how a corporate move – where
                employees’ entire families are also relocated – can be both stressful and challenging. Our trained
                and professional staff are there to make this transition as smooth as possible and offer unrivalled
                quality service. Our staff will pack, load, unload and arrange your employee’s new home making it
                ready for them and their family.
              </Typography>
            </CardContent>
            <CardContent>
              <div>
                <SvgButton
                  onClick={() => setCreateEstimateDialogOpen(true)}
                >
                  <Typography variant="subtitle2" align="center">
                    Get Quote
                  </Typography>
                </SvgButton>
              </div>
            </CardContent>
            <CreateEstimateDialog />
          </Container>
        </div>
      </div>
    </Grow>
  );
}
