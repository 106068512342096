/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Drawer,
  FormControlLabel, FormGroup, Grid, IconButton, TextField, Toolbar, Typography,
} from '@mui/material';
import { GetFormattedPhoneNumberString } from '@think-zambia-foundation/utils';
import {
  useCoreDialog, CoreDialogProvider, createClient,
  CoreApiProvider, useCoreApi,
} from '@think-zambia-foundation/context';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { SmartTypography } from '@think-zambia-foundation/components';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useWilocate } from '../../context';

function Wrapped() {
  const [creatingClient, setCreatingClient] = useState<boolean>(false);
  const {
    dialogOpen,
    setDialogOpen,
    submitDisabled,
    setSubmitDisabled,
  } = useCoreDialog();

  const {
    createEstimate,
    createEstimateDialogOpen,
    setCreateEstimateDialogOpen,
    creatingEstimate,
    createEstimateData,
    relocationType,
  } = useWilocate();

  const {
    coreApiClient,
  } = useCoreApi();

  const [insuranceServiceRequested, setSelectInsuranceServiceRequested] = useState<boolean>(false);
  const [packingServiceRequested, setSelectPackingService] = useState<boolean>(false);
  const [requestedMoveDate, setRequestedMoveDate] = useState<Dayjs>(dayjs(new Date()));
  const [moveFromAddressLine1, setMoveFromAddressLine1] = useState<string>();
  const [moveFromCity, setMoveFromCity] = useState<string>();
  const [moveFromProvince, setMoveFromProvince] = useState<string>();
  const [moveFromCountry, setMoveFromCountry] = useState<string>();
  const [moveToAddressLine1, setMoveToAddressLine1] = useState<string>();
  const [moveToCity, setMoveToCity] = useState<string>();
  const [moveToProvince, setMoveToProvince] = useState<string>();
  const [moveToCountry, setMoveToCountry] = useState<string>();
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone1, setPhone1] = useState<string>();
  const [insuredAmount, setInsuredAmount] = useState<number>();
  const [showSuccessMessage, setShowSuccessMessage] = useState<any>();

  useEffect(() => {
    setDialogOpen(createEstimateDialogOpen);
    // eslint-disable-next-line
  }, [createEstimateDialogOpen]);

  useEffect(() => {
    if (
      coreApiClient
      && email
      && moveFromAddressLine1
      && moveFromCity
      && moveFromCountry
      && moveToAddressLine1
      && moveToCity
      && moveToCountry
      && name
      && phone1
      && requestedMoveDate
      && (!insuranceServiceRequested || (insuranceServiceRequested && insuredAmount))
      && relocationType
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [
    email,
    moveFromAddressLine1,
    moveFromCity,
    moveFromCountry,
    moveToAddressLine1,
    moveToCity,
    moveToCountry,
    name,
    phone1,
    requestedMoveDate,
    insuranceServiceRequested,
    insuredAmount,
    relocationType,
  ]);

  function resetDialog() {
    setSelectInsuranceServiceRequested(false);
    setSelectPackingService(false);
    setRequestedMoveDate(dayjs(new Date()));
    setMoveFromAddressLine1(undefined);
    setMoveFromCity(undefined);
    setMoveFromProvince(undefined);
    setMoveFromCountry(undefined);
    setMoveToAddressLine1(undefined);
    setMoveToCity(undefined);
    setMoveToProvince(undefined);
    setMoveToCountry(undefined);
    setName(undefined);
    setEmail(undefined);
    setPhone1(undefined);
  }

  useEffect(() => {
    if (!dialogOpen) {
      resetDialog();
    }
  }, [dialogOpen]);

  const submit = () => {
    if (coreApiClient && name && email && phone1) {
      setCreatingClient(true);
      createClient({
        client: coreApiClient,
        name,
        email,
        phone1,
      }).then(({ data }) => {
        if (data) {
          const type = relocationType.toUpperCase().replace(/\//g, '').replace(/-/g, ' ');
          createEstimate({
            clientId: data?.createClient?.clientId,
            moveFromAddressLine1,
            moveFromCity,
            moveFromProvince,
            moveFromCountry,
            packingServiceRequested,
            insuranceServiceRequested,
            insuredAmount,
            moveToAddressLine1,
            moveToCity,
            moveToProvince,
            moveToCountry,
            requestedMoveDate: requestedMoveDate ? `${requestedMoveDate?.unix()}` : undefined,
            relocationType: type,
          });
        }
      })
        .finally(() => {
          setCreatingClient(false);
        });
    }
    resetDialog();
  };

  useEffect(() => {
    if (createEstimateData) {
      setShowSuccessMessage(createEstimateData);
    }
  }, [createEstimateData]);

  return (
    <Drawer
      anchor="bottom"
      open={dialogOpen}
      onClose={() => {
        setCreateEstimateDialogOpen(false);
        resetDialog();
      }}
    >
      <Container style={{
        backgroundColor: 'whitesmoke',
      }}
      >
        <Toolbar />
        <Toolbar />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={5}
          style={{
            height: '100%',
          }}
        >
          {showSuccessMessage
            ? (
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh',
                }}
              >
                <Grid
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid>
                    <Typography
                      sx={{ typography: { xs: 'h2', sm: 'h1', fontWeight: 'bold' } }}
                      color="#155EBA"
                      style={{ textAlign: 'center' }}
                    >
                      Thank you
                    </Typography>
                    <br />
                    <Typography color="#155EBA" style={{ textAlign: 'center' }}>The request was sent successfully </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      onClick={() => {
                        setCreateEstimateDialogOpen(false);
                        setShowSuccessMessage(null);
                      }}
                      style={{
                        color: '#155EBA',
                        margin: '10% 50vw 0 50vw',
                        padding: 0,
                      }}
                    >
                      <CloseTwoToneIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )
            : (
              <>
                <Grid item xs={12}>
                  <Typography variant="h3" color="primary">
                    Get Quote
                  </Typography>
                  <IconButton
                    onClick={() => setCreateEstimateDialogOpen(false)}
                    sx={{
                      position: 'absolute',
                      right: 10,
                      top: 10,
                    }}
                  >
                    <CloseTwoToneIcon />
                  </IconButton>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SmartTypography caption="Client information" text="" />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        fullWidth
                        required
                        label="Email Address"
                        name="Email Address"
                        variant="outlined"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email || ''}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Phone 1"
                        name="Phone1"
                        required
                        id="phone-1-tf"
                        onChange={(event) => {
                          const phoneInputNumber = event.target.value
                            .replace(/[^\d]/g, '')
                            .slice(3, 12);
                          setPhone1(phoneInputNumber);
                        }}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                        value={
                          phone1 ? `${GetFormattedPhoneNumberString({ phone: phone1 })}` : '+260 '
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Full Names"
                        required
                        label="Full Names"
                        type="text"
                        value={name || ''}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                        onChange={(e: { target: { value: any; }; }) => setName(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={requestedMoveDate || undefined}
                          onChange={(newValue) => setRequestedMoveDate(newValue || dayjs(new Date()))}
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField
                              InputProps={{ style: { backgroundColor: 'white' } }}
                              required
                              fullWidth
                              defaultValue=""
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                            />
                          )}
                          label="Requested Move Date"
                          disablePast
                          InputProps={{ style: { backgroundColor: 'white' } }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SmartTypography caption="Move From" text="" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Address"
                        required
                        label="Address"
                        type="text"
                        value={moveFromAddressLine1 || ''}
                        onChange={(e) => setMoveFromAddressLine1(e.target.value)}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="city"
                        required
                        label="city"
                        type="text"
                        value={moveFromCity || ''}
                        onChange={(e) => setMoveFromCity(e.target.value)}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Province"
                        label="Province"
                        type="text"
                        value={moveFromProvince || ''}
                        onChange={(e) => setMoveFromProvince(e.target.value)}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Country"
                        required
                        label="Country"
                        type="text"
                        value={moveFromCountry || ''}
                        onChange={(e) => setMoveFromCountry(e.target.value)}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SmartTypography caption="Move To" text="" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Address"
                        required
                        label="Address"
                        type="text"
                        value={moveToAddressLine1 || ''}
                        onChange={(e) => setMoveToAddressLine1(e.target.value)}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="City"
                        label="City"
                        required
                        type="text"
                        value={moveToCity || ''}
                        onChange={(e) => setMoveToCity(e.target.value)}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Province"
                        label="Province"
                        type="text"
                        value={moveToProvince || ''}
                        onChange={(e) => setMoveToProvince(e.target.value)}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="country"
                        label="country"
                        type="text"
                        required
                        value={moveToCountry || ''}
                        onChange={(e) => setMoveToCountry(e.target.value)}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item sm={6} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={packingServiceRequested}
                              onChange={(e) => setSelectPackingService(e.target.checked)}
                            />
                          )}
                          label="Do you want our packing services?"
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={insuranceServiceRequested}
                              onChange={(e) => setSelectInsuranceServiceRequested(e.target.checked)}
                            />
                          )}
                          label="Do you want additional insurance?"
                        />
                      </FormGroup>
                    </Grid>
                    {insuranceServiceRequested && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Insured Amount *"
                        label="Enter total value of items to be insured"
                        type="number"
                        required
                        focused
                        value={insuredAmount}
                        onChange={(event) => setInsuredAmount(parseFloat(event.target.value))}
                        InputProps={{ style: { backgroundColor: 'white' } }}
                      />
                    </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    variant="contained"
                    style={{
                      padding: '1em 2.5em',
                      borderRadius: 0,
                    }}
                    size="large"
                    onClick={() => submit()}
                    disabled={submitDisabled}
                    loading={creatingEstimate || creatingClient}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </>
            )}
        </Grid>
        <Toolbar />
      </Container>
    </Drawer>
  );
}

export function CreateEstimateDialog() {
  return (
    <CoreApiProvider>
      <CoreDialogProvider>
        <Wrapped />
      </CoreDialogProvider>
    </CoreApiProvider>
  );
}
