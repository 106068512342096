import { IconButton, Typography } from '@mui/material';
import React from 'react';
import { useWilocate } from '../../context';
import { CreateEstimateDialog } from '../CreateEstimateDrawer';

export function GetQuote(){
  const { setCreateEstimateDialogOpen } = useWilocate();
  return (
    <div>
      <IconButton
        sx={{
          borderRadius: '90px',
          background: '#0C67C9',
          position: 'fixed',
          right: 10,
          bottom: 10,
          padding: '0.8em',
          display: { xs: 'block', sm: 'none' },
        }}
        color="inherit"
        onClick={() => setCreateEstimateDialogOpen(true)}
      >
        <Typography variant="subtitle1" color="white">
          Get quote
        </Typography>
      </IconButton>
      <CreateEstimateDialog />
    </div>
  );
}
