import {
  Grid, Typography, Button, Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import { ServicesMenuItemContentProps } from './ServiceMenuItemContent.types';

export function ServicesMenuItemContent({
  title,
  subTitle,
  text,
  linkTo,
  taglines,
}: ServicesMenuItemContentProps) {
  return (
    <Grid container spacing={8}>
      <Grid item xs={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2" sx={{ typography: { fontWeight: 'bold' } }} color="secondary">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary">
              {subTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {text}
            </Typography>
          </Grid>
          <Grid item>
            <Link
              to={linkTo}
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                style={{
                  borderRadius: 0,
                  padding: '1em 2em',
                }}
                variant="contained"
              >
                <Typography variant="subtitle2" align="center">
                  Learn More
                </Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={4}>
          {taglines?.map((tagline, index) => (
            <Grid key={tagline} item xs={12}>
              <Typography variant="h6" color="secondary">
                {tagline}
              </Typography>
              {index < taglines.length - 1 && (
                <Divider style={{
                  paddingTop: '2em',
                }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
