/* eslint-disable react-hooks/exhaustive-deps */
import type { FC } from 'react';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import type {
  NormalizedCacheObject,
} from '@apollo/client';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useMutation,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import type { CreateEstimateProps, WilocateContextType, WilocateProviderProps } from './WilocateContextTypes';

const CREATE_ESTIMATE = gql`
mutation CreateEstimate(
  $clientId: ID!,
  $moveFromAddressLine1: String!,
  $moveFromCity: String!,
  $moveFromProvince: String,
  $moveFromCountry:String!,
  $moveToAddressLine1: String!,
  $moveToCity:String!,
  $moveToProvince: String,
  $moveToCountry: String!,
  $packingServiceRequested: Boolean,
  $insuranceServiceRequested: Boolean,
  $requestedMoveDate: String!,
  $insuredAmount: Float,
  $relocationType: String,
){
  createEstimate(
   clientId: $clientId,
   moveFromAddressLine1 :$moveFromAddressLine1,
   moveFromCity :$moveFromCity,
   moveFromProvince :$moveFromProvince,
   moveFromCountry :$moveFromCountry,
   moveToAddressLine1 :$moveToAddressLine1,
   moveToCity :$moveToCity,
   moveToProvince :$moveToProvince,
   moveToCountry :$moveToCountry,
   requestedMoveDate: $requestedMoveDate,
   packingServiceRequested: $packingServiceRequested,
   insuranceServiceRequested: $insuranceServiceRequested,
   insuredAmount: $insuredAmount,
   relocationType: $relocationType,
  ){
    estimateId
  }
}
`;

const WilocateContext = createContext<WilocateContextType>(undefined!);
export function useWilocate() {
  return useContext(WilocateContext);
}

const WilocateProviderBase: FC<WilocateProviderProps> = ({
  children,
  wilocateClient,
}) => {
  const [createEstimateDialogOpen, setCreateEstimateDialogOpen] = useState<boolean>(false);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState<boolean>(false);
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState<boolean>(false);
  const [topDrawerOpen, setTopDrawerOpen] = useState<boolean>(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState<boolean>(false);
  const [place, setPlace] = useState();
  const [relocationType, setRelocationType] = useState<string>('');

  const [createEstimateMutation, {
    error: errorCreatingEstimate,
    loading: creatingEstimate,
    data: createEstimateData,
  }] = useMutation(CREATE_ESTIMATE, {
    client: wilocateClient,
  });

  const createEstimate = async (
    variables: CreateEstimateProps,
  ) => createEstimateMutation({
    variables,
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_PLACE_API}?place_Id=${process.env.REACT_APP_WILOCATE_PLACE_ID}`)
      .then((response) => setPlace(response.data));
  }, []);

  return (
    <WilocateContext.Provider value={{
      createEstimateData,
      creatingEstimate,
      errorCreatingEstimate,
      createEstimate,
      createEstimateDialogOpen,
      place,
      setCreateEstimateDialogOpen,
      leftDrawerOpen,
      setLeftDrawerOpen,
      bottomDrawerOpen,
      setBottomDrawerOpen,
      rightDrawerOpen,
      setRightDrawerOpen,
      topDrawerOpen,
      setTopDrawerOpen,
      relocationType,
      setRelocationType,
    }}
    >
      {children}
    </WilocateContext.Provider>
  );
};

const cache = new InMemoryCache();

export const WilocateProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [wilocateClient, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    const httpLink = createHttpLink({
      uri: `${process.env.REACT_APP_WILOCATE_API_URL}`,
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
      },
    }));

    setClient(new ApolloClient({
      cache,
      link: authLink.concat(httpLink),
      connectToDevTools: true,
    }));
  }, []);

  useEffect(() => {
    if (wilocateClient) {
      wilocateClient.resetStore();
    }
  }, [wilocateClient]);

  if (!wilocateClient) {
    return <></>;
  }

  return (
    <WilocateProviderBase
      wilocateClient={wilocateClient}
    >
      {children}
    </WilocateProviderBase>
  );
};
